
import React, { useEffect } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import LinearProgress from '@mui/material/LinearProgress';

const PdfViewer = ({ dataResponse, options, isShowPdf, pdfFile, setPdfFile, setButtonDisabled  }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    useEffect (() => {
        async function getFilePdf() {
            if(dataResponse) {
                const blob = await dataResponse;
                const fileURL = URL.createObjectURL(blob); // Tạo URL từ blob để hiển thị
                setPdfFile(fileURL);
            }
        }
        getFilePdf();
        setButtonDisabled(false);
    }, [dataResponse]);

    return (
        <MDBox sx={{ height: "600px" }}>
            {(pdfFile) ? (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                    <Viewer
                        fileUrl={pdfFile}
                        plugins={[defaultLayoutPluginInstance]} // Plugin để có giao diện đầy đủ
                        {...options}
                    />
                    </Worker>
                ) : (
                    isShowPdf && (
                        <MDBox
                          textAlign="center"
                          sx={{ width: "100%", overflow: 'hidden'}}
                        >
                            <MDTypography>
                                Invoice Generation will take time, please wait...
                            </MDTypography>
                            <LinearProgress color="success" />
                        </MDBox>
                    ))
                }
        </MDBox>
    );
}   

export default PdfViewer;
