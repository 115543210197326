import HttpService from "./htttp.service";

class InvoicesService {
    getList = async(payload) => {
        const token = localStorage.getItem("token");
        const getListEndPoint = "invoices";
        return await HttpService.get(getListEndPoint, payload, token);
    }
}

export default new InvoicesService();
