import HttpService from "./htttp.service";

class ReportsService {
    downloadPdfFile = async(payload) => {
        const token = localStorage.getItem("");
        const getListEndPoint = "properties/report/group";
        return await HttpService.post(getListEndPoint, payload, token, true);
    }
}

export default new ReportsService();
