/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import React
import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PdfViewer from "examples/PdfViewer";
import ReportsService from "services/reports-service";

// Helpers
import Helpers from 'utils/helpers';

function Reports() {
  const [dataResponse, setDataResponse] = useState(null);
  const [errors, setErrors] = useState({
      startDateError: false,
      endDateError: false
  });

  const [pdfFile, setPdfFile] = useState(null);
  const [isShowPdf, setIsShowPdf] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedValue, setSelectedValue] = useState("GIB");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Lấy ngày hiện tại
    // const today = new Date();
    
    // Tính toán start date (ngày 15 của tháng trước)
    // const previousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 15);
    
    // Tính toán end date (ngày 15 của tháng này)
    // const currentMonth = new Date(today.getFullYear(), today.getMonth());


    const currentDate = new Date();
    const defaultValue = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;

    // Đặt giá trị mặc định cho start date và end date
    setStartDate(defaultValue);
    setTenantName('');
    // setEndDate(Helpers.formatDate(currentMonth));
  }, []);

  const fetchPdfFile = async (payload) => {
    try {
        const response = await ReportsService.downloadPdfFile(payload);
        setDataResponse(response);
    } catch (error) {
        console.error('Error fetching PDF:', error);
    }
  };

  const onChangeDate = (event) => {
    const { name, value } = event.target;

    if (name === "startDate") {
      setStartDate(value);
    }
    if (name === "endDate") {
      setEndDate(value);
    }
    const startDateFormat = name === "startDate" ? new Date(value): new Date(startDate);
    const endDateFormat = name === "endDate" ? new Date(value) : new Date(endDate);

    if (name === "startDate" && (startDateFormat > endDateFormat)) {
      setErrorMessage("Start date cannot be greater than end date");
      setErrors({ startDateError: true, endDateError: false });
      return;
    }
    if (name === "endDate" && (endDateFormat < startDateFormat)) {
      setErrorMessage("End date cannot be less than start date");
      setErrors({ startDateError: false, endDateError: true});
      return;
    }
    setErrorMessage("");
    setErrors({startDateError: false, endDateError: false});
  }

  const onChangeSelect = (event) => {
    const { value } = event.target;
    setSelectedValue(value);
  }

  const onChangeText = (event) => {
    const { value } = event.target;
    setTenantName(value);
  }

  const onHandleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      property_id: "c96a2dcb-58c7-4008-9b0d-43c3ee073822",
      period_id: "d7b86783-3b82-4d25-b70a-d28657dc8079",
      start_date: startDate,
      tenant_name: tenantName,
      // end_date: endDate,
      property_code: selectedValue
    }

    fetchPdfFile(payload);
    setIsShowPdf(true);
    setPdfFile(null);
    setButtonDisabled(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Reports
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2} sx={{ padding: "19px 20px"}}>
                  <Grid item xs={12} md={4}>
                    <MDBox component="form" role="form" method="POST" onSubmit={onHandleSubmit}>
                      <MDBox mb={2}>
                        <FormControl fullWidth>
                          <InputLabel id="property-code-select-label">
                              Property code
                          </InputLabel>
                          <Select
                            labelId="property-code-select-label"
                            value={selectedValue}
                            onChange={onChangeSelect}
                            displayEmpty
                            label="Property code"
                            sx={{
                                minWidth: 200,
                                height: '44px', // Set chiều cao cố định
                            }}
                          >
                            <MenuItem value={"GIB"}>GIB</MenuItem>
                            <MenuItem value={"YLC"}>YLC</MenuItem>
                            <MenuItem value={"YL3"}>YL3</MenuItem>
                          </Select>
                        </FormControl>
                      </MDBox>

                      <MDBox mb={2} textAlign="left">
                        <strong style={{ fontSize: '0.7em', color: '#666' }}>- OR -</strong> &nbsp;
                        <small style={{ fontSize: '0.6em', color: '#666' }}>
                          (Leave Tenant Name empty if you want to search by Property Code)
                        </small>
                      </MDBox>

                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Tenant Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{
                            "& .MuiInput-underline:after": { borderBottomColor: "#388e3c"},
                            "& .MuiInputLabel-root.Mui-focused": { color: "#388e3c" }
                          }}
                          value={tenantName}
                          onChange={onChangeText}
                          name="tenant_name"
                        />
                      </MDBox>
                      
                      <MDBox mb={2}>
                        <MDInput
                          type="month"
                          label="Month / Year"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{
                            "& .MuiInput-underline:after": { borderBottomColor: "#388e3c"},
                            "& .MuiInputLabel-root.Mui-focused": { color: "#388e3c" }
                          }}
                          value={startDate}
                          onChange={(e) => onChangeDate(e)}
                          name="startDate"
                          error={errors.startDateError}
                        />
                        {( errorMessage && errors.startDateError ) && (
                          <FormHelperText error>{errorMessage}</FormHelperText>
                        )}
                      </MDBox>
                      <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton
                          variant="gradient"
                          sx={{
                            padding: "12px 30px",
                            backgroundColor: "#388e3c",
                            color: "#ffffff",
                            borderRadius: "0.2rem",
                            "&:hover": {
                              backgroundColor: "#919191",
                              color: "#fff",
                            },
                            "&:disabled": {
                              background: "#e0e0e0"
                            }
                          }}
                          // disabled={errorMessage !== "" ||buttonDisabled || (startDate === '' || endDate === '')}
                          type="submit"
                        >
                          Download
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <PdfViewer
                      dataResponse={dataResponse}
                      options={{ theme: 'dark', defaultScale: 1.5 }}
                      isShowPdf={isShowPdf}
                      pdfFile={pdfFile}
                      setPdfFile={setPdfFile}
                      setButtonDisabled={setButtonDisabled}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
    </DashboardLayout>
  );
}

export default Reports;
