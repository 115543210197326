import HttpService from "./htttp.service";

class TenantsService {
    getList = async(payload) => {
        const token = localStorage.getItem("token");
        const getListEndPoint = "tenants"
        return await HttpService.get(getListEndPoint, payload, token);
    }
}

export default new TenantsService();
