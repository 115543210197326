import HttpService from "./htttp.service";

class PendingAmountService {
    downloadPdfFile = async(payload) => {
        const token = localStorage.getItem("");
        const getListEndPoint = "properties/report/pending-amount";
        return await HttpService.post(getListEndPoint, payload, token, true);
    }
}

export default new PendingAmountService();
