import HttpService from "./htttp.service";

class LeasesService {
    getList = async(payload) => {
        const token = localStorage.getItem("token");
        const getListEndPoint = "leases"
        return await HttpService.get(getListEndPoint, payload, token);
    }
    updateLease = async(payload) => {
        const token = localStorage.getItem("");
        const updateLeaseEndPoint = "leases-v2/" + payload.lease_id;
        return await HttpService.post(updateLeaseEndPoint, payload, token, false);
    }
}

export default new LeasesService();
