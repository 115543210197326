/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import React
import React, { useState, useEffect } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import FormHelperText from '@mui/material/FormHelperText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import leasesData from 'layouts/leases/data/leasesData';

import LinearProgress from '@mui/material/LinearProgress';
import EditLeases from "layouts/leases/editLease";

// Helpers
import Helpers from 'utils/helpers';

function Leases () {
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [tenantCode, setTenantCode] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const { columns, rows, getLeasesList, loading, buttonDisabled, setButtonDisabled, open, setOpen, isShowDialog, editedRow } = leasesData();

  const onChangeText = (event) => {
    const { value }  = event.target
    if (value.length < 3) {
      setErrorMessage('Tenant code value must be more equal than 3 characters');
      setErrors(true);
    } else {
      setErrorMessage('');
      setErrors(false);
    }
    setTenantCode(value);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotification(false);
  };

  const onHandleSubmit = (event) => {
    event.preventDefault();
    getLeasesList (1, 1000, tenantCode)
    setButtonDisabled(true);
    setIsShow(true);
  }

  useEffect(() => {
    getLeasesList (1, 1000, tenantCode);
    setIsEdited(false);
  }, [isEdited])

  return (
    <DashboardLayout>
      <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Leases
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2} sx={{ padding: "19px 20px"}}>
                  <Grid item xs={12} md={4}>
                    <MDBox component="form" role="form" method="POST" onSubmit={onHandleSubmit}>
                      <MDBox mb={2}>
                        <MDInput
                          type="text"
                          label="Tenant code"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          sx={{
                            "& .MuiInput-underline:after": { borderBottomColor: "#388e3c"},
                            "& .MuiInputLabel-root.Mui-focused": { color: "#388e3c" }
                          }}
                          value={tenantCode}
                          onChange={onChangeText}
                          name="tenant_code"
                        />
                        {( errorMessage && errors ) && (
                          <FormHelperText error>{errorMessage}</FormHelperText>
                        )}
                      </MDBox>
                      <MDBox mt={4} mb={1} textAlign="right">
                        <MDButton
                          variant="gradient"
                          sx={{
                            padding: "12px 30px",
                            backgroundColor: "#388e3c",
                            color: "#ffffff",
                            borderRadius: "0.2rem",
                            "&:hover": {
                              backgroundColor: "#919191",
                              color: "#fff",
                            },
                            "&:disabled": {
                              background: "#e0e0e0"
                            }
                          }}
                          disabled={ buttonDisabled || tenantCode === "" || errors }
                          type="submit"
                        >
                          Search
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MDBox sx={{ maxHeight: '360px', overflowY: 'auto' }}>
                      { isShow && loading && (
                        <MDBox textAlign="center" sx={{ width: "100%", overflow: 'hidden'}}>
                          <MDTypography>
                            <LinearProgress color="success" />
                          </MDTypography>
                        </MDBox>
                      )}
                      { isShow &&  !loading && rows && rows.length > 0 && (
                          <DataTable
                              table={{ columns, rows }}
                              isSorted={true}
                              showTotalEntries={false}
                              entriesPerPage={false}
                              noEndBorder
                          />
                        )
                      }
                      { isShow && !loading && rows && rows.length === 0 && 
                        (
                          <MDBox>
                            <MDTypography>
                              No lease found
                            </MDTypography>
                          </MDBox>
                        )
                      }
                    </MDBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        { isShowDialog && (
          <EditLeases open={open} setOpen={setOpen} setOpenNotification={setOpenNotification} rowData={editedRow} setIsShow={setIsShow} setIsEdited={setIsEdited}/>
        )}
        <Snackbar open={openNotification} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%', color: '#ffffff' }}
          >
            Update Lease Successfully !
          </Alert>
      </Snackbar>
    </DashboardLayout>
  );
}

export default Leases;
