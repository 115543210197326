import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url, params, token) => await this.request(this.getOptionsConfig("get", url, null, params, token));

  post = async (url, data, token, isPdf) => await this.request(this.getOptionsConfig("post", url, data, {}, token, isPdf));

  put = async (url, data) => await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) => await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url) => await this.request(this.getOptionsConfig("delete", url));

  getOptionsConfig = (method, url, data = null, params = {}, token = null, isPdf = false) => {
    const config =  {
      method,
      url,
      headers: { 'Content-Type': 'application/vnd.api+json' }
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (isPdf) {
      config.responseType = 'blob';
    }

    if (Object.keys(params).length > 0) {
      config.params = params;
    } else {
      config.data = data;
    }

    return config
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => {
          // Check if the status code is 200
          if (res.status !== 200) {
            // Log the error message if status code is not 200
            // console.log(`Error: ${res.status} - ${res.statusText}`);
            reject(res.data); // Reject with the response data
          } else {
            resolve(res.data); // Resolve if status code is 200
          }
        })
        .catch((ex) => {
          // In case of request failure, log the error response
          // console.log('Request failed:', ex.response?.data || ex.message);
          reject(ex.response?.data || ex.message); // Reject with error data or message
        });
    });
  }
}

export default new HttpService();
