/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState, useRef } from 'react';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material components
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import LeasesService from "services/leases-service";

export default function LeasesData() {
  const [leasesData, setLeasesData] = useState([])
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [editedRow, setEditedRow] = useState(null)

  const getLeasesList = async (pageIndex = '', pageSize = '', filter = "") => {
    try {
      setLoading(true);
      const data = await LeasesService.getList({
        limit: pageSize,
        page: pageIndex,
        sortField: "lease_number",
        sortDirection: "desc",
        filter: filter
      })
      setTotalPages(data.meta.last_page);

      const leasesList = data.data.map(({ id, lease_code, property: { property_code }, unit_names, rent_amount, due_date, tenants }) => ({
        id, lease_code, property_code, unit_names, rent_amount, tenants
      }));

      setLeasesData(leasesList);
      setButtonDisabled(true)
    } catch (error) {
      console.error('Error get leases list:', error);
    } finally {
      setLoading(false)
      setButtonDisabled(false)
    }
  }

  const handleEditLease = (item) => {
    setOpen(true);
    setIsShowDialog(true);
    setEditedRow(item);
  }

  useEffect(() => {
    const data = leasesData.map((item, index) => ({
      ...item,
      no: index,
      action: (
        <MDTypography component="a" href="#" color="text">
          <IconButton size="small" onClick={() => handleEditLease(item)} ><Icon>edit</Icon></IconButton>
        </MDTypography>
      )
    }));
    setRows(data);
  }, [leasesData]);

  return {
    columns: [
      { Header: "tenant code", accessor: "lease_code", enableSorting: true, align: "left" },
      { Header: "property code", accessor: "property_code", enableSorting: false, align: "left" },
      { Header: "unit", accessor: "unit_names", align: "left" },
      { Header: "rent amount", accessor: "rent_amount", align: "left" },
      { Header: "action", accessor: "action", align: "left" }
    ],
    rows: rows,
    totalPages: totalPages,
    getLeasesList: getLeasesList,
    loading: loading,
    buttonDisabled: buttonDisabled,
    setButtonDisabled: setButtonDisabled,
    open: open,
    setOpen: setOpen,
    isShowDialog: isShowDialog,
    editedRow: editedRow
  };
}
